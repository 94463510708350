import type { Dayjs } from "dayjs";
import type { DateRange, ValidRange } from "../types";

import dayjs from "dayjs";

import { classnames } from "@/js/utils/cambio";

interface MonthProps {
  hoveredDate: Dayjs | null;
  /** Dayjs date that should somewhere within the month to display */
  month: Dayjs;
  onHoverDate: (val: Dayjs) => void;
  onSelectRange: (range: DateRange) => void;
  selectedRange: DateRange;
  flavor?: "date" | "range";
  /** A tuple denoting the limits of the pickable range. If an entry is null, then the range has no limit */
  validRange: ValidRange;
}

/**
 * Shows dates of a month in a grid, with previous month and following month dates shown as disabled
 * just enough to fill out the first and last weeks, respectively.
 *
 * Biggest logic here is for all the states that a date could have:
 *
 * * bookend - this is the start or end of a selected range
 * * bookend-left/bookend-right - start/end, respectively, of the range
 * * disabled - whether date is from a different month or out of the valid range
 * * hovered - can happen when selectedRange has only one entry and the user hovers on a new date
 * * highlighted - when we have a hovered date, all dates between that and the selected date are
 *   highlighted. that can be before or after the selected date.
 * * selected - when we have two dates in the selected range, a date is selected when it is between
 */
export default function Month({
  validRange,
  hoveredDate,
  month,
  onSelectRange,
  selectedRange,
  onHoverDate,
  flavor = "range",
}: MonthProps) {
  const isRangeFlavor = flavor === "range";
  const startOfMonth = month.startOf("month");
  // how far to offset the first of the month to align with the day of the week
  const gridOffset = startOfMonth.day();
  const displayDates = Array.from(
    { length: Math.ceil((gridOffset + month.daysInMonth()) / 7) * 7 },
    (el, i) => startOfMonth.add(i - gridOffset, "day"),
  );

  /**
   * When selectedRange is empty or has a complete date range, clicking a date starts a new range
   * (ie, the callback is called with a single date). If we have one selected date, then the new
   * date gets prepended or appended, depending on whether it is before or after, respectively.
   */
  const onSelectDate = (date: Dayjs) => {
    if (!isRangeFlavor || !selectedRange.length || selectedRange.length === 2) {
      onSelectRange([date]);
    } else {
      onSelectRange(
        (selectedRange[0].isBefore(date) ?
          selectedRange.concat(date)
        : [date].concat(selectedRange)) as DateRange,
      );
    }

    onHoverDate(null);
  };

  return (
    <div className="Month">
      <h5>{month.format("MMM YYYY")}</h5>
      <div className="dates" onMouseLeave={() => onHoverDate(null)}>
        {Array.from({ length: 7 }, (el, i) => (
          <h6 key={i}>{dayjs().day(i).format("ddd")}</h6>
        ))}
        {displayDates.map((time, i) => (
          <span
            key={i}
            className={classnames({
              disabled:
                !month.isSame(time, "month") ||
                (validRange[0] && dayjs(validRange[0]).isAfter(time)) ||
                (validRange[1] && dayjs(validRange[1]).isBefore(time)),
              bookend:
                // TODO: I've seen this error because day is undefined? But can tell from where...
                selectedRange.some((day) => day?.isSame(time, "day")) &&
                month.isSame(time, "month"),
              // can't just use CSS for this for neighbor selectors because they might be on
              // different months...
              ...(isRangeFlavor ?
                {
                  "bookend-left":
                    hoveredDate ?
                      selectedRange[0]?.isSame(time, "day") && hoveredDate.isAfter(time, "day")
                    : selectedRange[0]?.isSame(time, "day") && month.isSame(time, "month"),
                  "bookend-right":
                    hoveredDate ?
                      selectedRange[0]?.isSame(time, "day") && hoveredDate?.isBefore(time, "day")
                    : selectedRange[1]?.isSame(time, "day") && month.isSame(time, "month"),
                }
              : {}),
              // doing this in JS means it won't get removed when we hover over the grid gaps
              hovered: month.isSame(time, "month") && hoveredDate?.isSame(time, "day"),
              highlighted:
                isRangeFlavor &&
                hoveredDate &&
                month.isSame(time, "month") &&
                ((hoveredDate.isBefore(selectedRange[0]) &&
                  time.isBefore(selectedRange[0]) &&
                  time.isAfter(hoveredDate)) ||
                  (hoveredDate.isAfter(selectedRange[0]) &&
                    time.isAfter(selectedRange[0]) &&
                    time.isBefore(hoveredDate))),
              selected:
                // this line just ensures we don't highlight outside a given month
                month.isSame(time, "month") &&
                selectedRange[0]?.isBefore(time, "day") &&
                selectedRange[1]?.isAfter(time, "day"),
            })}
            onClick={() => onSelectDate(time)}
            onMouseEnter={
              !isRangeFlavor || selectedRange.length === 1 ? () => onHoverDate(time) : null
            }
          >
            {time.date()}
          </span>
        ))}
      </div>
    </div>
  );
}
