import type { Dayjs } from "dayjs";
import type { DateRange, ValidRange } from "./types";

import dayjs from "dayjs";
import { useLayoutEffect, useState } from "react";

import IconButton from "@/components/Button/IconButton";

import usePrevProps from "@/js/hooks/usePrevProps";
import { classnames } from "@/js/utils/cambio";

import Button from "../Button";
import Month from "./Month";

interface CalendarProps {
  /** A tuple denoting the limits of the pickable range. If an entry is null, then the range has no limit */
  validRange?: ValidRange;
  onSelectRange: (range: DateRange) => void;
  /** Selected date range can be between lengths zero and two */
  selectedRange: DateRange;
  /** If a date flavor, we show a single month at a time and only have one selection */
  flavor?: "date" | "range";
}

/**
 * Displays two months side-by-side with clickable dates, subject to the `validRange`. Arrows on
 * either side can navigate month by month. The selected date range is denoted by two 'bookend'
 * dates, with each date between displayed as "selected". There is another state, though, when
 * only one part of a range has been selected, other dates can be "hovered", where each date
 * between the hovered date and selected date is "highlighted."
 */
export default function Calendar({
  validRange = [null, dayjs().startOf("day")],
  selectedRange = [],
  onSelectRange,
  flavor = "range",
}: CalendarProps) {
  /**
   * When only a single date is selected, every date hovered over gets set as state. We can't simply
   * rely on CSS here because the two dates could span different months.
   */
  const [hoveredDate, setHoveredDate] = useState<Dayjs>(null);
  /** We take the month of the latest-selected date as the right-most month to show */
  const [visibleMonth, setVisibleMonth] = useState<Dayjs>(
    (selectedRange[1] || selectedRange[0] || dayjs()).startOf("month"),
  );
  const prevSelectedRange = usePrevProps(selectedRange);

  const monthProps = {
    hoveredDate,
    onHoverDate: setHoveredDate,
    onSelectRange,
    selectedRange,
    validRange,
  };

  /**
   * When we get a new selected range, we need to update our visibleMonth state. Doing it this way
   * is generally ill-advised in React, instead relying on a `key` on the Calendar to just re-mount
   * it when the selectedRange changes.
   *
   * But here we don't want to simply reset everything. We actually only want to reset the state if
   * the new latest-selected date is not in view. For example, if the previous date was in March and
   * the next one in Feb, we don't want to move Feb over to the right, because that's a jarring
   * experience. Leave it on the left in that case. For this reason, this state resetting must
   * happen here.
   */
  useLayoutEffect(() => {
    /**
     * Find out which date changed. If the end date has changed, use that; if the start date
     * changed, use that. Default to the end date, if it exists. If not, use today.
     */
    const hasEndDateChanged =
      prevSelectedRange.current[1] &&
      selectedRange[1] &&
      !selectedRange[1]?.isSame(prevSelectedRange.current[1], "day");
    const hasStartDateChanged =
      prevSelectedRange.current[0] &&
      selectedRange[0] &&
      !selectedRange[0].isSame(prevSelectedRange.current[0], "day");

    const dateToLocate =
      hasEndDateChanged ? selectedRange[1]
      : selectedRange.length === 1 || hasStartDateChanged ?
        // this "add" is because visible month is the right-hand side, but the start month
        // we want to stay on the left. unless there is no end date, in which case it's okay
        // that it's on the right-side if that's where it was picked.
        selectedRange[0].add(
          flavor === "date" || selectedRange[0].isSame(visibleMonth, "month") ? 0 : 1,
          "month",
        )
      : selectedRange[1] || dayjs();

    // only reset state if the new visible month is not in view,
    // so if not current visible month or visible month - 1
    if (
      (dateToLocate && dateToLocate.startOf("month").isBefore(visibleMonth.subtract(1, "month"))) ||
      dateToLocate.startOf("month").isAfter(visibleMonth)
    ) {
      setVisibleMonth(dateToLocate.startOf("month"));
    }
  }, [selectedRange.map((day) => day?.format("MMM DD, YYYY") || "").join()]);

  return (
    <div className={classnames("Calendar", { [flavor]: flavor !== "range" })}>
      <IconButton
        icon="arrow-back"
        onClick={(evt) => {
          evt.stopPropagation();
          setVisibleMonth(visibleMonth.subtract(1, "month"));
        }}
      />
      {flavor === "range" ?
        <Month month={visibleMonth.subtract(1, "month")} {...monthProps} />
      : null}
      <Month flavor={flavor} month={visibleMonth} {...monthProps} />
      <IconButton
        icon="arrow-forward"
        onClick={(evt) => {
          evt.stopPropagation();
          setVisibleMonth(visibleMonth.add(1, "month"));
        }}
      />
      {flavor === "date" ?
        <footer>
          <Button flavor="link" onClick={() => onSelectRange([dayjs()])}>
            Today
          </Button>
        </footer>
      : null}
    </div>
  );
}

export type { DateRange, ValidRange } from "./types";
